<template>
  <b-container fluid class="overflow-hidden p-2">
    <apexchart
      type="line"
      height="300"
      :options="chartOptions"
      :series="series"
      class="mt-3"
      v-if="loaded"
    ></apexchart>
  </b-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "RecipeStatsWidget",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    apexchart: VueApexCharts
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      stats: {},
      loaded: false,
      series: [
        {
          name: "Created",
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: "Modified",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: "Recipe Activity",
          align: "left"
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date"
          }
        },
        yaxis: {
          title: {
            text: "Recipes"
          },
          min: 0,
          max: 10
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    };
  },
  watch: {},
  computed: {
    ...mapState(["isAuthenticated", "userProfile"])
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"])
  },
  mounted() {
    this.bindObject("stats/recipes", null, "stats").then(() => {
      let labels = [
        ...new Set([
          ...Object.keys(this.stats.created),
          ...Object.keys(this.stats.modified)
        ])
      ].sort();
      let createdData = [];
      let modifiedData = [];
      // Add created and modified stats
      labels.forEach((label, i) => {
        // Add created
        if (label in this.stats.created) {
          createdData[i] = this.stats.created[label];
        }
        // Add modified
        if (label in this.stats.modified) {
          modifiedData[i] = this.stats.modified[label];
        }
      });
      // Update chart data
      this.chartOptions.xaxis.categories = [...labels];
      this.series[0].data = [...createdData];
      this.series[1].data = [...modifiedData];
      this.loaded = true;
    });
  }
};
</script>

<style scoped>
.card-deck {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}

.card {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}

.card-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card-title {
  text-align: center;
}

.card-footer {
  font-size: 60%;
  font-weight: 700;
  height: 54px;
}
</style>
