<template>
  <div>
    <div class="header">
      <span class="header-text">
        <b>Welcome {{ userProfile.name }}!</b>
        <small class="ml-2"
          >Your last sign-in was {{ userProfile.lastSignIn }}</small
        >
      </span>
      <span class="float-right">
        <b-dropdown variant="outline-success" size="sm" right>
          <template #button-content>
            <i class="fas fa-plus"></i>
            Add Widget
          </template>
          <b-dropdown-item @click="addWidget('recipe-stats')"
            >Recipe Stats Widget</b-dropdown-item
          >
          <b-dropdown-item @click="addWidget('user-stats')"
            >User Stats Widget</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="addWidget('inbox')"
            >Inbox Widget</b-dropdown-item
          >
          <b-dropdown-item @click="addWidget('shared-inbox')"
            >Shared Inbox Widget</b-dropdown-item
          >
        </b-dropdown>
      </span>
    </div>
    <grid-layout
      :layout.sync="widgets"
      :col-num="3"
      :row-height="200"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :vertical-compact="true"
      :use-css-transforms="true"
      :margin="[20, 20]"
    >
      <grid-item
        v-for="widget in widgets"
        :static="false"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        :key="widget.i"
        @moved="widgetMoved"
        @resized="widgetResized"
      >
        <b-container fluid>
          <b-row>
            <b-col>
              <small
                :class="
                  'pl-2 font-weight-bolder text-' +
                    ('color' in widget ? widget.color : 'body')
                "
              >
                <i
                  :class="'icon' in widget ? widget.icon : 'fas fa-chart-bar'"
                ></i>
                {{ "title" in widget ? widget.title : "New Widget" }}</small
              >
              <span class="float-right">
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  class="border-0"
                  v-b-modal="'modal_widget_settings_' + widget.i"
                  ><i class="fas fa-cog"></i
                ></b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <recipe-stats-widget
                :widget="widget"
                v-if="widget.type === 'recipe-stats'"
              ></recipe-stats-widget>
              <user-stats-widget
                :widget="widget"
                v-if="widget.type === 'user-stats'"
              ></user-stats-widget>
              <inbox-widget
                :widget="widget"
                v-if="widget.type === 'inbox'"
              ></inbox-widget>
              <shared-inbox-widget
                :widget="widget"
                v-if="widget.type === 'shared-inbox'"
              ></shared-inbox-widget>
            </b-col>
          </b-row>
        </b-container>

        <b-modal
          :id="'modal_widget_settings_' + widget.i"
          title="Widget Settings"
          @ok="saveWidget(widget)"
          @close="deleteWidget(widget['.key'])"
          @show="editWidget = { ...widget }"
        >
          <template #default="{}">
            <b-form-group label="Title">
              <b-form-input v-model="editWidget.title" />
            </b-form-group>
            <b-form-group label="Icon">
              <b-input-group>
                <b-form-input v-model="editWidget.icon" />
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    disabled
                    class="text-body icon-button"
                  >
                    <i :class="editWidget.icon"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Color">
              <b-input-group>
                <b-form-select
                  :options="colorOptions"
                  v-model="editWidget.color"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    disabled
                    :class="'text-body icon-button bg-' + editWidget.color"
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Shared Inbox"
              v-if="editWidget.type === 'shared-inbox'"
            >
              <b-form-select
                :options="sharedInboxes"
                value-field=".key"
                text-field="name"
                v-model="editWidget.inbox.key"
              />
            </b-form-group>
          </template>
          <template #modal-footer="{ ok, cancel, close }">
            <b-button variant="primary" @click="ok()">
              Save
            </b-button>
            <b-button variant="danger" @click="close()">
              Delete
            </b-button>
            <b-button variant="secondary" @click="cancel()">
              Cancel
            </b-button>
          </template>
        </b-modal>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import RecipeStatsWidget from "@/components/widgets/RecipeStatsWidget";
import UserStatsWidget from "@/components/widgets/UserStatsWidget";
import InboxWidget from "@/components/widgets/InboxWidget";
import SharedInboxWidget from "@/components/widgets/SharedInboxWidget";
import VueGridLayout from "vue-grid-layout";

export default {
  name: "Dashboard",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    RecipeStatsWidget,
    UserStatsWidget,
    InboxWidget,
    SharedInboxWidget
  },
  data() {
    return {
      bind: [{ ref: "helpdesk/mailboxes", target: "inboxes" }],
      widgets: [],
      editWidget: {
        title: "",
        icon: "fas fa-chart-bar",
        color: "body",
        inbox: {
          key: {},
          name: ""
        }
      },
      inboxes: [],
      draggable: true,
      resizable: true,
      colorOptions: [
        { value: "body", text: "Default" },
        { value: "primary", text: "Blue" },
        { value: "secondary", text: "Gray" },
        { value: "success", text: "Green" },
        { value: "danger", text: "Red" },
        { value: "warning", text: "Yellow" },
        { value: "info", text: "Teal" }
      ]
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    userUid() {
      return this.userProfile.uid;
    },
    sharedInboxes() {
      return this.inboxes.filter(inbox => {
        return (
          inbox.name !== "Archive" &&
          inbox.name !== "Spam" &&
          inbox.name !== "Trash"
        );
      });
    }
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject("admin/widgets", uid, "widgets");
        }
      }
    }
  },
  methods: {
    getWidget(i) {
      let widget;
      this.widgets.forEach(w => {
        if (w.i === i) {
          widget = { key: w[".key"], ...w };
        }
      });
      return widget;
    },
    widgetMoved(i) {
      let widget = this.getWidget(i);
      let key = widget.key;
      delete widget.key;
      this.updateObject("admin/widgets/" + this.userUid, key, widget);
    },
    widgetResized(i) {
      let widget = this.getWidget(i);
      let key = widget.key;
      delete widget.key;
      this.updateObject("admin/widgets/" + this.userUid, key, widget);
    },
    addWidget(type) {
      let widget = {
        x: this.widgets.length % (this.colNum || 3),
        y: this.widgets.length + (this.colNum || 3), // puts it at the bottom
        w: 1,
        h: 1,
        i: Date.now(),
        title: "New Widget",
        color: "body"
      };
      // Handle widget type
      let inbox;
      switch (type) {
        case "recipe-stats":
          widget.title = "Recipe Stats";
          widget.type = type;
          widget.icon = "fas fa-chart-line";
          break;
        case "user-stats":
          widget.title = "User Stats";
          widget.type = type;
          widget.icon = "fas fa-chart-line";
          break;
        case "inbox":
          widget.title = "Inbox";
          widget.type = type;
          widget.icon = "fas fa-inbox";
          break;
        case "shared-inbox":
          inbox = this.sharedInboxes[0];
          widget.inbox = { key: inbox[".key"], ...inbox };
          widget.title = "Inbox: " + widget.inbox.name;
          widget.type = type;
          widget.icon = "fas fa-inbox";
          break;
        default:
          widget.type = "sample";
          widget.icon = "fas fa-chart-bar";
      }
      this.createObject("admin/widgets/" + this.userUid, widget).catch(err => {
        console.error("Failed adding widget", err);
        window.toastr.error("An error occurred adding the widget: " + err);
      });
    },
    saveWidget(widget) {
      const key = widget[".key"];
      const updated = { ...widget, ...this.editWidget };
      if (updated.type === "shared-inbox") {
        let inbox = this.inboxes[
          this.getIndex(this.inboxes, updated.inbox.key)
        ];
        updated.inbox = { key: inbox[".key"], ...inbox };
      }
      this.updateObject("admin/widgets/" + this.userUid, key, updated)
        .then(() => {
          window.toastr.success("Widget saved successfully.");
          this.editWidget = {
            title: "",
            icon: "fas fa-chart-bar",
            color: "body"
          };
        })
        .catch(err => {
          console.error("Failed saving widget", err);
          window.toastr.error("An error occurred saving the widget: " + err);
        });
    },
    deleteWidget(key) {
      this.deleteObject("admin/widgets/" + this.userUid, key)
        .then(() => {
          window.toastr.success("Widget deleted successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error("An error occurred while deleting the widget.");
        });
    }
  }
};
</script>

<style>
.vue-grid-item.vue-grid-placeholder {
  background: #ff9800 !important;
}
</style>

<style scoped>
.header {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  margin: 10px 20px 0 20px;
  padding: 10px;
  width: calc(100% - 40px);
  height: 50px;
}

.header-text {
  margin-top: 2px;
}

.icon-button {
  width: 42px;
  opacity: 100 !important;
}

.bg-body {
  background-color: #212529 !important;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
