<template>
  <b-container fluid class="overflow-hidden">
    <b-row>
      <b-col>
        <div class="card-deck">
          <div class="card card-shadow">
            <div class="card-body">
              <h3 class="card-title">
                {{ tickets.length }}
              </h3>
            </div>
            <div class="card-footer bg-dark text-light">
              Total Requests
            </div>
          </div>
          <div class="card card-shadow">
            <div class="card-body">
              <h3 class="card-title">
                {{ inProgressCount }}
              </h3>
            </div>
            <div class="card-footer bg-dark text-light">
              In Progress
            </div>
          </div>
          <div class="card card-shadow">
            <div class="card-body">
              <h3
                :class="
                  'card-title text-' + (overdueCount > 0 ? 'danger' : 'body')
                "
              >
                {{ overdueCount }}
              </h3>
            </div>
            <div class="card-footer bg-dark text-light">
              Overdue
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import { DateTime } from "luxon";

export default {
  name: "InboxWidget",
  mixins: [FirebaseMixin, UtilsMixin],
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bind: [{ ref: "helpdesk/meta/slas", target: "slas" }],
      tickets: [],
      slas: []
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject("helpdesk/inboxes/", uid, "tickets");
        }
      }
    },
    tickets: {
      immediate: false,
      handler(tickets) {
        tickets.forEach((ticket, i) => {
          this.tickets[i].slas = this.computeSLAs(ticket, ticket.sender);
        });
      }
    },
    slas: {
      immediate: false,
      handler() {
        this.tickets.forEach((ticket, i) => {
          this.tickets[i].slas = this.computeSLAs(ticket, ticket.sender);
        });
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
    inProgressCount() {
      return this.tickets.filter(ticket => {
        return ticket.status !== "Completed" && ticket.status !== "Cancelled";
      }).length;
    },
    overdueCount() {
      return this.tickets.filter(ticket => {
        let overdue = false;
        if (Array.isArray(ticket.slas)) {
          ticket.slas.forEach(sla => {
            if (!sla.completed && sla.time_remaining === 0) {
              overdue = true;
            }
          });
        }
        return overdue;
      }).length;
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    computeSLAs(ticket, sender) {
      let slas = [];
      this.slas.forEach(sla => {
        if (sender.type === sla.role) {
          if (ticket.status === "Completed" || ticket.status === "Cancelled") {
            sla.completed = true;
            sla.time_remaining = 0;
            sla.percent = 100;
            sla.style = "success";
          } else {
            const now = DateTime.now();
            switch (sla.type) {
              case "first-response": {
                let updateCount;
                if ("updates" in ticket) {
                  updateCount = Object.values(ticket.updates).filter(update => {
                    return update.type === "update";
                  }).length;
                } else {
                  updateCount = 0;
                }
                if (updateCount > 0) {
                  sla.completed = true;
                  sla.time_remaining = 0;
                } else {
                  sla.completed = false;
                  let created = DateTime.fromMillis(ticket.date);
                  let elapsed = now.diff(created, "minutes");
                  sla.time_remaining = sla.duration - elapsed.as("minutes");
                }
              }
              // eslint-disable-next-line no-fallthrough
              case "follow-up": {
                if ("updates" in ticket) {
                  let replies = Object.values(ticket.updates).filter(update => {
                    return update.type === "update";
                  });
                  if (replies.length > 0) {
                    let lastReply = replies[replies.length - 1];
                    let updated = DateTime.fromMillis(lastReply.date);
                    let elapsed = now.diff(updated, "minutes");
                    sla.time_remaining = sla.duration - elapsed.as("minutes");
                  } else {
                    let created = DateTime.fromMillis(ticket.date);
                    let elapsed = now.diff(created, "minutes");
                    sla.time_remaining = sla.duration - elapsed.as("minutes");
                  }
                } else {
                  let created = DateTime.fromMillis(ticket.date);
                  let elapsed = now.diff(created, "minutes");
                  sla.time_remaining = sla.duration - elapsed.as("minutes");
                }
                sla.completed = false;
              }
              // eslint-disable-next-line no-fallthrough
              case "completion": {
                let created = DateTime.fromMillis(ticket.date);
                let elapsed = now.diff(created, "minutes");
                sla.time_remaining = sla.duration - elapsed.as("minutes");
              }
            }
            if (sla.time_remaining === 0) {
              sla.percent = 100;
            } else {
              sla.percent =
                100 - Math.round((sla.time_remaining / sla.duration) * 100);
            }
            if (!sla.completed && sla.percent >= 75) {
              sla.style = "danger";
            } else if (!sla.completed && sla.percent >= 50) {
              sla.style = "warning";
            } else {
              sla.style = "success";
            }
          }
          slas.push(sla);
        }
      });
      return slas;
    }
  }
};
</script>

<style scoped>
.card-deck {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}

.card {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}

.card-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card-title {
  text-align: center;
}

.card-footer {
  font-size: 60%;
  font-weight: 700;
  height: 54px;
}
</style>
